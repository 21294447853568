<template>
  <div
    ref="dateRangeEl"
    :class="computedClasses"
    v-bind="$attrs"
  >
    <button
      :disabled="disabled"
      type="button"
     
      @click="openCalendar('start')"
    >
      <span class="label">{{ t('checkIn') }}</span>
      <!-- Look into better formatting maybe use formatAsShortDateLong? -->
      <span :class="{ empty: !computedStartDate }">{{ computedStartDate || t('addDate') }}</span>
    </button>

    <button
      :disabled="disabled"
      type="button"
     
      @click="openCalendar('end')"
    >
      <span class="label">{{ t('checkOut') }}</span>
      <span :class="{ empty: !computedEndDate }">{{ computedEndDate || t('addDate') }}</span>
    </button>
  </div>

  <ClientOnly v-if="!disabled">
    <!-- Is it possible to use Popover component here? -->
    <div
      v-if="isLargeBreakpoint"
      v-show="showForm"
      ref="floatingEl"
      class="floating-box"
     
      :style="floatingStyles"
    >
      <LazySearchDatesForm
        :dates="{ start: bookingEstimateParams.dateStart, end: bookingEstimateParams.dateEnd }"
        :disabled-dates="disabledDates"
        :unavailable-days="rvUnavailableDays"
        :min-date="rvMinBookableDate"
        :max-date="rvMaxBookableDate"
        :timezone="rvComputedTimezone"
        show-clear-button
        @update:dates="updateDates"
      />
      <div
        ref="floatingArrowEl"
        :style="{
          left:
            middlewareData.arrow?.x != null
              ? `${middlewareData.arrow.x}px`
              : '',

        }"
        class="floating-arrow"
      />
    </div>

    <OverlayDrawer
      v-else
      :visible="showForm"
      :no-teleport="noTeleport"
      hide-header
      @close="showForm = false"
    >
      <LazySearchDatesForm
        :dates="{ start: bookingEstimateParams.dateStart, end: bookingEstimateParams.dateEnd }"
        :disabled-dates="disabledDates"
        :unavailable-days="rvUnavailableDays"
        :min-date="rvMinBookableDate"
        :max-date="rvMaxBookableDate"
        :timezone="rvComputedTimezone"
        @update:dates="updateDates"
      />

      <template #footer>
        <ZButton
          link
          variant="primary"
          @click="updateDates({ start: null, end: null })"
        >
          {{ t('clearDates') }}
        </ZButton>
        <ZButton
          class="ml-auto"
          @click="showForm = false"
        >
          {{ t('actions.apply') }}
        </ZButton>
      </template>
    </OverlayDrawer>
  </ClientOnly>
</template>

<script setup lang="ts">
import { arrow, autoUpdate, offset, shift, useFloating } from '@floating-ui/vue'
import type { BlockedRange } from '~/types'

import type { SearchDates } from '~/types/search'

const props = defineProps<{
  noTeleport?: boolean
  disabled?: boolean
  startDate?: string
  endDate?: string
  disabledDates?: BlockedRange[]
  flush?: boolean
}>()

const emit = defineEmits<{
  update: [dates: SearchDates['dates']]
}>()

const { t } = useI18n()

const { isLargeBreakpoint } = useBreakpoint()

const dateRangeEl = ref<HTMLDivElement>()
const floatingEl = ref<HTMLDivElement>()
const floatingArrowEl = ref<HTMLDivElement>()
const { floatingStyles, middlewareData } = useFloating(dateRangeEl, floatingEl, {
  placement: 'bottom',
  middleware: [offset(8), shift({ padding: 8 }), arrow({ element: floatingArrowEl })],
  whileElementsMounted: autoUpdate,
})

const computedClasses = computed(() => [
  'date-range',
  {
    flush: props.flush,
  },
])

const showForm = ref(false)
function openCalendar(_rangePosition: string) {
  showForm.value = true
}

onClickOutside(floatingEl, () => {
  showForm.value = false
}, { ignore: [dateRangeEl] })

const { bookingEstimateParams, updateDateRange } = useBookingEstimate()

const computedStartDate = computed(() => props.startDate || bookingEstimateParams.value.dateStart)
const computedEndDate = computed(() => props.endDate || bookingEstimateParams.value.dateEnd)

async function updateDates(dates: SearchDates['dates']) {
  updateDateRange({
    dateStart: dates.start || null,
    dateEnd: dates.end || null,
  })

  // Close the form if both dates are selected
  // Temporary disabling auto-close for small screens because the overlay drawer is buggy
  if (dates.start && dates.end && isLargeBreakpoint.value) {
    showForm.value = false
  }

  emit('update', dates)
}

defineExpose({
  openCalendar,
})

const { rvComputedTimezone, rvMinBookableDate, rvMaxBookableDate, rvUnavailableDays } = useRvDetails()
</script>

<style lang="scss" scoped>
.date-range {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1px;
  position: relative;
  border: 1px solid getColor('primary-100');
  border-radius: 0.25rem;

  &::after {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    width: 1px;
    background: getColor('primary-100');
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  button {
    text-align: left;
    border: none;
    background: none;
    padding: 0.5rem 1rem;
    color: getColor('primary-500');

    .label {
      display: block;
      @include caption;
      color: getColor('primary-350');

      + span {
        @include strong-1;

        &.empty {
          color: getColor('primary-350');
        }
      }
    }
  }

  &.flush {
    border-left: 0;
    border-right: 0;
    border-radius: 0;

    button:first-child {
      padding-left: 0;
    }

    button:last-child {
      padding-right: 0;
    }
  }
}

.floating-arrow {
  position: absolute;
  width: 12px;
  height: 12px;
  top: -7px;
  transform: rotate( -45deg );
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  background-color: #fff;
}
</style>

<i18n lang="json">
{
  "en": {
    "addDate": "Add date",
    "checkIn": "Check in",
    "checkOut": "Check out",
    "clearDates": "Clear dates"
  },
  "fr": {
    "addDate": "Ajouter une date",
    "checkIn": "Date de départ",
    "checkOut": "Date de retour",
    "clearDates": "Réinitialiser les dates"
  }
}
</i18n>
